import React from "react";
import data from "../data/error404";
import CustomCursor from "../components/CustomCursor";

const Test = () => {
  const [mouseDown, setMouseDown] = React.useState(false);

  const handleClick = ({ type }) => {
    if (type === "mousedown") {
      setMouseDown(true);
    }

    if (type === "mouseup") {
      setMouseDown(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("mousedown", handleClick);
    window.addEventListener("mouseup", handleClick);

    return () => {
      window.removeEventListener("mousedown", handleClick);
      window.removeEventListener("mouseup", handleClick);
    };
  });
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <CustomCursor mouseDown={mouseDown} />
    </div>
  );
};

export default Test;
